import produce from 'immer';

const initialState = {
  errorMessage: null
};

export default (state = initialState, action) =>
  produce(state, newState => {
    switch (action.type) {
      case 'CLEAR_ERROR_MESSAGE':
        newState.errorMessage = null;
        return newState;
      case 'DISPLAY_ERROR_MESSAGE':
        newState.errorMessage = action.payload;
        return newState;

      default:
        return state;
    }
  });
