import { saveAs } from "file-saver";

import config from "../config";

export const fetchOrders = ({state, limit, page}) => (dispatch, _, {baseApi}) =>
  dispatch({
    type: 'PRINT_ORDERS',
    payload: baseApi.get(`/printer/orders?state=${state}&limit=${limit}&page=${page}&sort=sentAt&direction=desc`)
  });

export const fetchOrder = reference => (dispatch, _, {baseApi}) =>
  dispatch({
    type: 'PRINT_ORDER',
    payload: baseApi.get(`/printer/order/${reference}`)
  });

export const downloadOrders = orders => (dispatch, getState) => {
  const {user} = getState();

  let headers = new Headers();
  headers.append('Authorization', `Bearer ${user.token}`);

  const params = orders
    .map((order) => {
      return `printOrderRequestId[]=${order.printOrderRequestId}`;
    })
    .join('&');

  return dispatch({
    type: 'DOWNLOAD_ORDERS',
    promise: fetch(`${config.baseApiUri}/printer/orders/download?${params}`, {
      credentials: 'same-origin',
      method: 'get',
      headers
    })
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, `EVERPRESS-batch-download.zip`);
      })
  });
};

export const downloadOrder = order => (dispatch, getState) => {
  const {user} = getState();

  let headers = new Headers();
  headers.append('Authorization', `Bearer ${user.token}`);

  return dispatch({
    type: 'DOWNLOAD_ORDER',
    promise: fetch(`${config.baseApiUri}/printer/order/${order.reference}/download`, {
      credentials: 'same-origin',
      method: 'get',
      headers
    })
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, `EVERPRESS-${order.reference}.zip`);
      })
  });
};

export const shipOrder = reference => (dispatch, _, {baseApi}) =>
  dispatch({
    type: 'SHIP_ORDER',
    payload: baseApi.post(`/printer/order/${reference}/ship`)
  });
