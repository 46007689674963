import React, { PureComponent } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import DataTable from 'react-data-table-component';
import moment from "moment";
import PropTypes from "prop-types";

import { Paper } from "@material-ui/core";

import * as printerActions from "../../actions/printer";

const columns = [
  { selector: 'reference', name: 'Reference' },
  { selector: 'campaignName', name: 'Campaign' },
  { selector: 'sentAt', name: 'Created' },
  { selector: 'deliveryDate', name: 'Ship By' },
  { selector: 'quantity', name: 'Quantity' },
  { selector: 'positions', name: 'Positions' }
];

class PrintOrders extends PureComponent {
  state = {
    currentPage: 0,
    rows: [],
    selectedRows: []
  }

  componentDidMount() {
    this.fetchOrders();
  }

  componentDidUpdate(prevProps) {
    if (this.props.status !== prevProps.status) {
      this.fetchOrders();
    }
    if (
      this.props.orders.results &&
      prevProps.orders.results !== this.props.orders.results
    ) {
      this.setState({
        rows: this.props.orders.results.map((order) => ({
          ...order,
          sentAt: moment(order.sentAt).format('Do MMM YYYY'),
          deliveryDate: moment(order.deliveryDate).format('Do MMM YYYY'),
        })),
      });
    }
  }

  fetchOrders() {
    this.props.fetchOrders({
      state: this.props.status,
      limit: this.props.limitPerPage,
      page: this.state.currentPage
    });
  }

  handlePageClick = data => {
    let selected = data.selected + 1; // orders API uses 1-based indexing
    this.setState({ currentPage: selected }, () => {
      this.fetchOrders();
    });
  }

  handleRowClick = row => {
    this.props.history.push(`/printer/order/${row.reference}`);
  }

  handleRowSelect = state => {
    this.setState({
      selectedRows: state.selectedRows
    });
  }

  onDownloadSelected = () => {
    this.props.downloadOrders(this.state.selectedRows);
  }

  render() {
    const { ordersCount, limitPerPage } = this.props;
    const { rows } = this.state;

    return (
      <div className="row">
        <div className="col-md-12">
          <Paper>
            <div className="kt-padding-15">
              <button
                type="button"
                className="btn btn-brand btn-bold btn-sm text-uppercase"
                onClick={this.onDownloadSelected}
              >
                Download Selected
              </button>
            </div>
            <DataTable
              noHeader
              columns={columns}
              data={rows}
              onRowClicked={this.handleRowClick}
              selectableRows
              onSelectedRowsChange={this.handleRowSelect}
              style={{ borderRadius: '4px', cursor: 'pointer' }}
            />
            <div className="kt-pagination kt-pagination--brand">
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                breakLabel="..."
                pageCount={Math.ceil(ordersCount / limitPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={Math.min(5, ordersCount)}
                onPageChange={this.handlePageClick}
                containerClassName="kt-pagination__links"
                activeClassName="kt-pagination__link--active"
                nextClassName="kt-pagination__link--next"
                previousClassName="kt-pagination__link--prev"
              />
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ printer }) => {
  return {
    orders: printer.orders,
    ordersCount: printer.orders.total
  }
};

PrintOrders.propTypes = {
  limitPerPage: PropTypes.number
}

PrintOrders.defaultProps = {
  limitPerPage: 25
}

export default connect(
  mapStateToProps,
  { ...printerActions }
)(PrintOrders);
