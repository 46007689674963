import React from "react";
import { connect } from "react-redux";
import SearchDropdown from "../../../app/partials/layout/SearchDropdown";
import UserNotifications from "../../../app/partials/layout/UserNotifications";
import QuickPanelToggler from "./QuickPanelToggle";
import UserProfile from "../../../app/partials/layout/UserProfile";

class Topbar extends React.Component {
  render() {
    const { isAdmin } = this.props;
    return (
      <div className="kt-header__topbar">
        {isAdmin && (
          <>
            <SearchDropdown icon="flaticon2-search-1" />

            <UserNotifications
              skin="light"
              iconType=""
              icon="flaticon2-bell-alarm-symbol"
              type="primary"
              dot="false"
            />

            <QuickPanelToggler />
          </>
        )}

        <UserProfile showBadge={true} />
      </div>
    );
  }
}

export default connect(
  ({ user }) => ({
    isAdmin: user.permissions.ROLE_ADMIN
  })
)(Topbar);
