export default {
  header: {
    self: {},
    items: [
      {
        title: "New",
        root: true,
        alignment: "left",
        page: "dashboard/new",
        translate: "MENU.NEW"
      },
      {
        title: "Active",
        root: true,
        alignment: "left",
        page: "dashboard/active",
        translate: "MENU.ACTIVE"
      },
      {
        title: "Shipped",
        root: true,
        alignment: "left",
        page: "dashboard/shipped",
        translate: "MENU.SHIPPED"
      },
    ]
  },
  aside: {
    self: {},
    items: [
      {
        title: "Jobs",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: "dashboard",
        translate: "Jobs",
        bullet: "dot"
      }
    ]
  }
};
