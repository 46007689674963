const config = {
  baseApiUri: process.env.REACT_APP_API_BASE_URI,
  jwtRoutesToValidate: ['PRINT_ORDERS'],
  jwtExpirationThreshold: 0,
  loginErrorMessage: {
    tokenExpired: "You've been logged out due to inactivity."
  }
};

export default config;
