import React, {Suspense} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {LayoutSplashScreen} from "../../_metronic";

import PrintOrders from "./PrintOrders";
import PrintOrder from "./PrintOrder";

export default function Dashboard() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* Redirect from root URL to /dashboard. */}
        <Redirect exact from="/" to="/dashboard/new" />
        <Redirect exact from="/dashboard" to="/dashboard/new" />
        <Route exact path="/dashboard/new" render={props => <PrintOrders {...props} status="new" />} />
        <Route exact path="/dashboard/active" render={props => <PrintOrders {...props} status="active" />} />
        <Route exact path="/dashboard/shipped" render={props => <PrintOrders {...props} status="shipped" />} />
        <Route path="/dashboard" component={PrintOrders}/>
        <Route path="/printer/order/:reference" component={PrintOrder}/>
        {/*
          <Route path="/dashboard" component={Dashboard1} />
          <Route path="/google-material" component={GoogleMaterialPage} />
          <Route path="/react-bootstrap" component={ReactBootstrapPage} />
          <Route path="/docs" component={DocsPage} />
          <Redirect to="/error/error-v1" />
        */}
      </Switch>
    </Suspense>
  );
}
