// export {default as user} from './user';

import { combineReducers } from 'redux';

import { metronic } from '../_metronic';
import user from './user';
import printer from './printer';
import login from './login';

export const rootReducer = combineReducers({
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer, // TODO this is key for menu config etc
  user: user,
  printer: printer,
  login: login
});
