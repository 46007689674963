import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as userActions from "../../actions/user";

class Logout extends Component {
  componentDidMount() {
    this.props.logout();
  }

  render() {
    return <Redirect to="/auth" />;
  }
}

export default connect(null, { ...userActions })(Logout);
