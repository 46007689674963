import React, { useState } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import * as userActions from '../../actions/user';
import * as loginActions from '../../actions/login';

function Login(props) {
  const { intl, login, errorMessage, clearErrorMessage } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: '2.5rem'
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: '3.5rem' });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: '2.5rem' });
  };

  const handleLogin = async ({ email, password }, { setStatus }) => {
    clearErrorMessage(); // We clear the error once you submit the form
    enableLoading();

    try {
      await login(email, password);
    } catch (error) {
      setStatus(error.message);
    } finally {
      disableLoading();
    }
  };

  return (
    <>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
          </div>

          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validate={values => {
              const errors = {};

              if (!values.email) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.email = intl.formatMessage({
                  id: 'AUTH.VALIDATION.REQUIRED_FIELD'
                });
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = intl.formatMessage({
                  id: 'AUTH.VALIDATION.INVALID_FIELD'
                });
              }

              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: 'AUTH.VALIDATION.REQUIRED_FIELD'
                });
              }

              return errors;
            }}
            onSubmit={handleLogin}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status || errorMessage ? (
                  <div role="alert" className="alert alert-danger">
                    {status && <div className="alert-text">{status}</div>}
                    {errorMessage && (
                      <div className="alert-text">{errorMessage}</div>
                    )}
                  </div>
                ) : (
                  ''
                )}

                <div className="form-group">
                  <TextField
                    type="email"
                    label="Email"
                    margin="normal"
                    className="kt-width-full"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Password"
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div>

                <div className="kt-login__actions">
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isSubmitting}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading
                      }
                    )}`}
                    style={loadingButtonStyle}
                  >
                    Sign In
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ login }) => {
  return {
    errorMessage: login.errorMessage
  };
};

export default injectIntl(
  connect(mapStateToProps, { ...userActions, ...loginActions })(Login)
);
