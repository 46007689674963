import jwtDecode from 'jwt-decode';

import * as userActions from '../actions/user';
import * as loginActions from '../actions/login';

import config from '../config';

export default function jwtMiddleware({ routesToValidate }) {
  return ({ dispatch, getState }) => next => action => {
    if (routesToValidate.includes(action.type)) {
      const { token } = getState().user;

      if (token) {
        const tokenExpiration = jwtDecode(token).exp;

        const tokenExpirationTimeInSeconds =
          tokenExpiration - Math.floor(Date.now() / 1000);
        // If required, we can force early invalidation by adjusting the threshold
        if (
          tokenExpiration &&
          tokenExpirationTimeInSeconds <= config.jwtExpirationThreshold
        ) {
          dispatch(
            loginActions.displayErrorMessage(
              config.loginErrorMessage.tokenExpired
            )
          );
          dispatch(userActions.logout());
        }
      }
    }
    return next(action);
  };
}
