import produce from "immer";
import jwtDecode from "jwt-decode";

function getLogoutState(initialState, state) {
  return {
    ...initialState
  };
}

const initialState = {
  token: null,
  userId: null
};

export default (state = initialState, action) =>
  produce(state, newState => {
    switch (action.type) {
      case "SET_USER":
        if (action.payload === null) {
          return getLogoutState(initialState, state);
        } else {
          try {
            const { username, userId, permissions } = jwtDecode(
              action.payload.token
            );

            return {
              ...state,
              email: username,
              permissions,
              userId,
              ...action.payload
            };
          } catch (error) {
            console.log({ error }); // TODO: STUFF
            // If token is invalid, log out user
            return getLogoutState(initialState, state);
          }
        }

      default:
        return state;
    }
  });
