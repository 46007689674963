import axios from "axios";

export default class Api {
  constructor({ baseUri, params }) {
    this.axios = axios.create({
      baseURL: baseUri,
      params,
      headers: {} // hack to fix Axios issue https://github.com/axios/axios/issues/385
    });

    this.axios.interceptors.response.use(
      this.onResponse.bind(this),
      this.onError.bind(this)
    );

    const { post, get, put } = this.axios;
    Object.assign(this, { post, get, put, delete: this.axios.delete });
  }

  setToken(token) {
    this.axios.defaults.headers["Authorization"] = token
      ? `Bearer ${token}`
      : null;
  }

  setErrorMiddleware(errorMiddleware) {
    this.errorMiddleware = errorMiddleware;
  }

  setParams(params) {
    this.axios.defaults.params = params;

    this.axios.interceptors.request.use(config => {
      // Do something before request is sent
      return {
        ...config,
        params: {
          ...this.axios.defaults.params,
          ...config.params
        }
      };
    });
  }

  onResponse(response) {
    return response.data;
  }

  onError(error) {
    error.message = "An error occured on the server, please try again later.";

    if (this.errorMiddleware) {
      // This function mutates `error`
      this.errorMiddleware(error);
    }

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      // Override error.message if Unauthorised
      if (error.response.status === 401) {
        error.message =
          "Hmmm... we don’t recognise that email and password combo, please try again.";
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);

    throw error;
  }
}
