export const setUser = user => {
  return {
    type: "SET_USER",
    payload: user
  };
};

export const logout = () => dispatch =>
  dispatch({
    type: "SET_USER",
    payload: null
  });

export const login = (email, password) => (dispatch, _, { baseApi }) =>
  dispatch({
    type: "LOGIN_USER",
    async payload() {
      const response = await baseApi.post("/login", { email, password });

      if (response && response.token) {
        dispatch(setUser({ token: response.token }));
      }
    }
  });
