import React, { Component } from "react";
import * as printerActions from "../../actions/printer";
import { connect } from "react-redux";
import clsx from "clsx";
import moment from "moment";
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from "../../app/partials/content/Portlet";

import getOptimImgUrl from "../../helpers/getOptimImgUrl";

class PrintOrder extends Component {
  state = {
    shipped: false,
    shipError: null
  }

  componentDidMount() {
    this.props.fetchOrder(this.props.match.params.reference);
  }

  onDownload = () => {
    this.props.downloadOrder(this.props.order);
  }

  onShip = async () => {
    try {
      await this.props.shipOrder(this.props.order.reference);
      this.setState({
        shipped: true
      });
    } catch (error) {
      this.setState({
        shipError: error.message
      });
    }
  };

  render() {
    const { order } = this.props;
    const { shipped, shipError } = this.state;

    return (
      <>
        {order && (
          <>
            <div className="row">
              <div className="col-md-12">
                <Portlet>
                  <PortletHeader
                    title={`Reference: ${order.reference} / ${order.campaignName}, Campaign ID: ${order.campaignId}`}
                    toolbar={
                      <PortletHeaderToolbar>
                        <span
                          className={clsx(
                            'kt-badge',
                            'kt-badge--inline',
                            'kt-badge--md',
                            'kt-badge--outline',
                            'kt-badge--rounded',
                            'kt-badge--bold',
                            'text-capitalize',
                            {
                              'kt-badge--warning': order.state === 'new',
                              'kt-badge--brand': order.state === 'active',
                              'kt-badge--success': order.state === 'shipped',
                            }
                          )}
                        >
                          {order.state === 'shipped' ? `Shipped ${moment(order.shippedAt).format('DD/MM/YYYY HH:mm')}` : order.state}
                        </span>
                      </PortletHeaderToolbar>
                    }
                  />

                  <PortletBody>
                    <div className="row">
                      <div className="col-md-6">
                      <dl>
                        <dt>Positions</dt>
                        <dd>{order.positions}</dd>

                        <dt>Quantity</dt>
                        <dd>{order.quantity}</dd>

                        <dt>Ship By</dt>
                        <dd>{moment(order.deliveryDate).format('Do MMM YYYY')}</dd>

                        {order.user && (
                          <>
                            <dt>Job Raised By</dt>
                            <dd>{order.user.firstName} {order.user.lastName} - {order.user.email}</dd>
                          </>
                        )}

                        <dt>Notes</dt>
                        <dd style={{ 'whiteSpace': 'pre-line' }}>{order.notes}</dd>
                      </dl>
                      </div>
                      <div className="col-md-6">
                      <dl>
                        <dt>Garment Suppliers</dt>

                        <div className="row">
                          {order.garmentSuppliers.map((garmentSupplier) => {
                            return (
                                <div className="col-md-12" key={garmentSupplier.productSupplier.name}>
                                  <dd>{garmentSupplier.productSupplier.name}</dd>
                                </div>
                            );
                          })}
                        </div>
                      </dl>
                      </div>
                    </div>

                    <span className="pr-4">
                      <button
                        type="button"
                        className="btn btn-brand btn-bold btn-sm text-uppercase"
                        onClick={this.onDownload}
                      >
                        Download ZIP
                      </button>
                      {order.permissions.ship && (
                        <>
                          <button
                            className="btn btn-success btn-bold btn-sm text-uppercase kt-margin-l-10"
                            onClick={this.onShip}
                            disabled={shipped || shipError}
                          >
                            {shipped ? 'Shipped' : 'Ship'}
                          </button>
                          <div className="kt-error_container">
                            <div className="kt-error kt-margin-t-10">
                              {shipError}
                            </div>
                          </div>
                        </>
                      )}
                    </span>
                  </PortletBody>
                </Portlet>
              </div>
            </div>

            <div className="row">
              {order.visualProofs.map((visualProof) => {
                const isEmbeddable = /\.pdf$/ig.test(visualProof.url);
                return (
                  <div className="col-md-12" key={visualProof.url}>
                    <Portlet>
                      <PortletHeader title={`Visual Proof`}/>

                      <PortletBody>
                        {isEmbeddable
                          ? <iframe title="Visual Proof" src={visualProof.url} height={842}/>
                          : <img
                            alt="Visual Proof"
                            src={getOptimImgUrl(visualProof.url)}
                          />
                        }
                      </PortletBody>
                    </Portlet>
                  </div>
                );
              })}
            </div>

            <div className="row">
              <div className="col-md-6">

              </div>
            </div>
          </>
        )}

      </>
    );
  }
}

const mapStateToProps = ({ printer, user }) => {
  return {
    order: printer.order,
    user: {
      token: user.token
    }
  }
};

export default connect(
  mapStateToProps,
  { ...printerActions }
)(PrintOrder);
