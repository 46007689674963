import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createPromise } from 'redux-promise-middleware';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { rootReducer } from '../reducers';
import config from '../config';

import jwtMiddleware from '../services/jwtMiddleware';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: 'root', // TODO: CONFIG?
  storage,
  whitelist: ['user']
};

export default ({ middlewareExtraArguments } = {}) => {
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(
    persistedReducer,
    composeEnhancers(
      applyMiddleware(
        thunk.withExtraArgument(middlewareExtraArguments),
        jwtMiddleware({
          routesToValidate: config.jwtRoutesToValidate
        }),
        createPromise()
      )
    )
  );

  const persistor = persistStore(store);

  return { store, persistor };
};
