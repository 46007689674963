import produce from "immer";

const initialState = {
  orders: {},
  ordersCount: null,
  order: {
    artwork: [],
    visualProofs: [],
    garmentSuppliers: [],
    permissions: {}
  }
};

export default (state = initialState, action) =>
  produce(state, newState => {
    switch (action.type) {
      case 'PRINT_ORDERS_FULFILLED':
        newState.orders = action.payload;
        newState.ordersCount = action.payload.total;
        return newState;

      case 'PRINT_ORDER_FULFILLED':
        newState.order = action.payload;
        return newState;

      default:
        return state;
    }
  });