export const clearErrorMessage = () => dispatch =>
  dispatch({
    type: 'CLEAR_ERROR_MESSAGE',
    payload: null
  });

export const displayErrorMessage = message => dispatch =>
  dispatch({
    type: 'DISPLAY_ERROR_MESSAGE',
    payload: message
  });
