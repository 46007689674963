/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import { withRouter } from "react-router-dom";
// import BreadCrumbs from "./components/BreadCrumbs";

import * as builder from "../../ducks/builder";
import * as printerActions from "../../../actions/printer";

class SubHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOrderView: props.location.pathname.includes('/order/'),
      search: '',
      searchError: null
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        isOrderView: this.props.location.pathname.includes('/order/')
      });
    }
  }
  handleSearchSubmit = async event => {
    event.preventDefault();
    if (!this.state.search || !/^[0-9-]+$/.test(this.state.search)) {
      this.setState({
        searchError: 'Please enter a valid job reference.'
      });
      return;
    }
    try {
      this.setState({
        searchError: null
      });
      await this.props.fetchOrder(this.state.search);
      this.props.history.push(`/printer/order/${this.state.search}`);
    } catch {
      this.setState({
        searchError: 'Job not found.'
      });
    }
  }
  render() {
    const {
      subheaderCssClasses,
      subheaderContainerCssClasses,
      subheaderMobileToggle,
      order
    } = this.props;
    const { isOrderView, search, searchError } = this.state;
    const today = new Date();
    const short_months = dt => {
      Date.shortMonths = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
      return Date.shortMonths[dt.getMonth()];
    };

    return (
      <div
        id="kt_subheader"
        className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}
      >
        <div className={`kt-container ${subheaderContainerCssClasses}`}>
          <div className="kt-subheader__main">
            {subheaderMobileToggle && (
              <button
                className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                id="kt_subheader_mobile_toggle"
              >
                <span />
              </button>
            )}

            {isOrderView &&
              <span className="kt-subheader__desc kt-margin-r-5">{order.reference}</span>
            }

            <form className="form-inline" onSubmit={this.handleSearchSubmit}>
              <div className="form-group">
                <label>Find job:</label>
                <input
                  type="text"
                  value={search}
                  className="form-control form-control-sm kt-margin-l-5"
                  onChange={e => this.setState({ search: e.target.value })}
                />
              </div>
              <button
                type="submit"
                className="btn btn-brand btn-bold btn-sm text-uppercase kt-margin-l-5"
              >
                Go
              </button>
              <div className="kt-error_container">
                <div className="kt-error kt-margin-l-5">
                  {searchError}
                </div>
              </div>
            </form>
          </div>

          {!isOrderView &&
            <div className="kt-subheader__toolbar">
              <div className="kt-subheader__wrapper">
                <button type="button" className="btn kt-subheader__btn-daterange">
                  <span
                    className="kt-subheader__btn-daterange-title"
                    id="kt_dashboard_daterangepicker_title"
                  >
                    Today:
                  </span>
                  &nbsp;
                  <span
                    className="kt-subheader__btn-daterange-date"
                    id="kt_dashboard_daterangepicker_date"
                  >
                    {short_months(today)} {today.getDate().toString()}
                  </span>
                  &nbsp;
                  <i className="flaticon2-calendar-1"></i>
                </button>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  subheaderCssClasses: builder.selectors.getClasses(store, {
    path: "subheader",
    toString: true
  }),
  subheaderContainerCssClasses: builder.selectors.getClasses(store, {
    path: "subheader_container",
    toString: true
  }),
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  subheaderMobileToggle: objectPath.get(
    store.builder.layoutConfig,
    "subheader.mobile-toggle"
  ),
  order: store.printer.order
});

export default withRouter(connect(
  mapStateToProps,
  {...printerActions}
)(SubHeader));


